<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-1">
                <h5 class="mt-2">Mapa</h5>
              </div>
              <div class="col-sm-7">
                <button
                  type="button"
                  class="btn bg-frontera text-white mr-2 align-top py-1"
                  data-toggle="modal"
                  data-target="#modal_aside_mapa"
                  style="cursor: pointer"
                >
                  <i class="fas fa-search-location" style="font-size: 21px"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Buscar</span>
                  </div>
                </button>
                <label class="badge bg-frontera text-white mr-2 align-top">
                  <input
                    type="checkbox"
                    id="geocerca"
                    v-model="form.geocercas"
                    @change="showGeocercas()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-map-marked-alt p-1"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Geocercas</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="traza"
                    @change="showDraggable()"
                  />
                  <i style="font-size: 22px" class="fas fa-route p-1"></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Trazabilidad</span>
                  </div>
                </label>

                <label class="badge bg-frontera text-white">
                  <input
                    type="checkbox"
                    v-model="form.vehiculosAct"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Activos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-success">Activos</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white align-top ml-2">
                  <input
                    type="checkbox"
                    v-model="form.postulados"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Postulados"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-warning">Postulados</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white ml-2">
                  <input
                    type="checkbox"
                    v-model="form.vehiculosInact"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Inactivos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-danger">Inactivos</span>
                  </div>
                </label>
                <button
                  type="button"
                  class="btn btn-dark ml-2 align-top py-1"
                  @click="showCarros(1)"
                  style="cursor: pointer"
                >
                  <i style="font-size: 22px" class="fas fa-sync"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Actualizar</span>
                  </div>
                </button>
              </div>
              <div class="col-sm-4">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Mapa</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <gmap-map
                      :center="center"
                      :zoom="zoom"
                      :tilt="tilt"
                      :heading="heading"
                      style="width: 100%; height: 650px"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(m, index) in markersTraza"
                        :position="m.position"
                        :icon="m.icon"
                        :animation="2"
                        @click="toggleInfoWindow(m, index, m.info)"
                      ></gmap-marker>
                      <gmap-cluster
                        :gridSize="50"
                        :zoomOnClick="true"
                        :enableRetinaIcons="true"
                        :minimumClusterSize="3"
                        ref="cluster"
                        :imagePath="'/img/map_cluster/m'"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :icon="m.icon"
                          :animation="2"
                          @click="toggleInfoWindow(m, index, m.info)"
                        ></gmap-marker>
                      </gmap-cluster>
                      <gmap-info-window
                        :options="infoOptions"
                        :position="infoWindowPos"
                        :opened="infoWinOpen"
                        @closeclick="infoWinOpen = false"
                      >
                        <div v-html="infoContent"></div>
                      </gmap-info-window>
                      <gmap-polygon
                        v-for="poligono in poligonos"
                        :options="{ fillColor: poligono.color }"
                        :key="poligono.id"
                        :paths="poligono.path"
                        :editable="false"
                        :draggable="false"
                        @click="
                          toggleInfoWindowPoli(
                            poligono,
                            poligono.id,
                            poligono.info
                          )
                        "
                      />
                      <gmap-circle
                        v-for="circular in circunferencias"
                        :options="{ fillColor: circular.color }"
                        :key="circular.id"
                        :center="circular.center"
                        :radius="circular.radius"
                        :info="circular.info"
                        @click="
                          toggleInfoWindowCir(
                            circular,
                            circular.id,
                            circular.info
                          )
                        "
                      ></gmap-circle>
                    </gmap-map>
                    <div class="row">
                      <div class="col-md-12" style="text-align: center">
                        <label for class="badge bg-light pt-2 pb-0 mb-0 mt-1">
                          <label class="badge alert-default-info">
                            <i
                              style="font-size: 13px"
                              class="fas fa-map-marked-alt p-1 text-primary"
                            ></i>
                            <strong style="font-size: 13px"
                              >Cargue o Descargue</strong
                            >
                            <i
                              style="font-size: 13px"
                              class="fas fa-map-marked-alt p-1 text-warning"
                            ></i>
                            <strong style="font-size: 13px"
                              >Disponibilidad</strong
                            >
                            <i
                              style="font-size: 13px"
                              class="fas fa-map-marked-alt p-1 text-danger"
                            ></i>
                            <strong style="font-size: 13px"
                              >Indisponibilidad</strong
                            >
                          </label>
                          <label
                            class="badge alert-default-primary"
                            v-if="form.vehiculosAct"
                          >
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-secondary"
                            >
                            </i>

                            <strong style="font-size: 13px">
                              Con Turno Activo: {{ countActV }}</strong
                            >
                          </label>
                          <label
                            class="badge alert-default-warning"
                            v-if="form.postulados"
                          >
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-secondary"
                            ></i>
                            <strong style="font-size: 13px"
                              >Postulado: {{ countPost }}</strong
                            >
                          </label>
                          <label
                            class="badge alert-default-danger"
                            v-if="form.vehiculosInact"
                          >
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-secondary"
                            ></i>
                            <strong style="font-size: 13px"
                              >Con Turno finalizado: {{ countInactV }}</strong
                            >
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-secondary"
                            ></i>

                            <strong style="font-size: 13px"
                              >Sin Turno activo: {{ countInact }}</strong
                            >
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-5"
                    id="traza"
                    style="display: none; position: absolute"
                  >
                    <div
                      class="card card-frontera card-outline collapsed-header"
                      id="trazaHeader"
                    >
                      <div class="card-header pt-0 pb-0" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="fas fa-route text-success"></i>
                            <b>&nbsp;Administrar Traza</b>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="trazaHeader"
                        data-parent="#traza"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="checkTurno"
                                  v-model="filter.checkTurno"
                                  class="custom-control-input"
                                  :disabled="filter.vehiculo_id != ''"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkTurno"
                                  >Turno</label
                                >
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="form-group col-md-6"
                              v-if="filter.checkTurno"
                            >
                              <label># Turno</label>
                              <input
                                type="number"
                                @keypress="onlyNumber"
                                class="form-control form-control-sm"
                                placeholder="Turno"
                                v-model="filter.turno_id"
                                :disabled="filter.vehiculo_id != ''"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!solicitudes.length"
                              v-show="!filter.checkTurno"
                            >
                              <label>Vehiculo</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Placa"
                                v-model="filter.vehiculo_id"
                                :disabled="filter.turno_id != ''"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                          </div>
                          <div id="div_fechas" v-show="filter.vehiculo_id">
                            <div class="row">
                              <div class="col-md-6">
                                <label>Fecha Inicio</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_inicio"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                              <div class="col-md-6">
                                <label>Fecha Fin</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_fin"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div
                              class="form-group col-md-6"
                              v-if="
                                (puntosTraza.length && filter.vehiculo_id) ||
                                  (puntosTraza.length && filter.turno_id)
                              "
                            >
                              <label>Cantidad</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="Cantidad Puntos"
                                v-model="filter.cantidad"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <br />
                              <div
                                class="btn-group float-right pt-1"
                                role="group"
                                v-if="puntosTraza[0] && filter.cantidad"
                              >
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="atras"
                                  :disabled="!markersTraza[1]"
                                  @click="backPoint()"
                                >
                                  <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="adelante"
                                  :disabled="
                                    markersTraza.length > puntosTraza.length
                                  "
                                  @click="nextPoint()"
                                >
                                  <i class="fas fa-angle-double-right"></i>
                                </button>
                              </div>
                            </div>
                            <div
                              class="form-group col-md-12"
                              v-if="
                                (puntosTraza.length && filter.vehiculo_id) ||
                                  (puntosTraza.length && filter.turno_id)
                              "
                            >
                              <div class="card card-outline card-navy">
                                <div class="card-header pt-2 pb-2">
                                  <h3 class="card-title">
                                    <h6>
                                      <i class="fas fa-truck"></i>
                                    </h6>
                                  </h3>
                                  <div class="card-tools">
                                    <button
                                      type="button"
                                      class="btn btn-tool"
                                      data-card-widget="collapse"
                                      data-toggle="tooltip"
                                      title="Collapse"
                                    >
                                      <i class="fas fa-plus"></i>
                                    </button>
                                  </div>
                                  <div v-html="html"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="showTraza()"
                              v-if="
                                (filter.fecha_inicio && filter.fecha_fin) ||
                                  filter.turno_id
                              "
                            >
                              Buscar
                              <i class="fas fa-search"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-dark"
                              @click="limpiarMapa()"
                            >
                              Limpiar
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table class="table team-roster-table">
              <thead align="center">
                <tr>
                  <th>
                    <img
                      src="/img/crr_aguas_p_r.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.aguasPotRie"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_azul.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.pozos"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_residual.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.residual"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_combustible.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.combustible"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_calderas.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.calderas"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_bat4.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.batePad"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_industrial.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.industrial"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th>
                    <img
                      src="/img/crr_lodos.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.lodos"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <!-- Camiones de vacio -->
                  <th>
                    <img
                      src="/img/crr_con_turno.png"
                      class="figure-img img-fluid rounded"
                    />
                    <label class="badge bg-frontera text-white m-2">
                      <input
                        type="checkbox"
                        v-model="filtersOperaciones.vacio"
                        @change="showCarros(1)"
                      />
                    </label>
                  </th>
                  <th v-if="form.vehiculosInact">
                    <img
                      src="/img/crr_rojo.png"
                      class="figure-img img-fluid rounded"
                    />
                  </th>
                </tr>
              </thead>
              <tbody align="center">
                <tr>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Agua potable - Agua riego</b><br />
                    <span class="badge badge-success">{{ aguaPoRi }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Pozos satelites</b><br />
                    <span class="badge badge-success">{{ pozos }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Residual</b><br />
                    <span class="badge badge-success">{{ residual }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Combustible</b><br />
                    <span class="badge badge-success"> {{ combustible }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Calderas</b><br />
                    <span class="badge badge-success">{{ caldera }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Bateria 4 - Pad3</b><br />
                    <span class="badge badge-success">{{ bateria }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Agua Industrial</b><br />
                    <span class="badge badge-success">{{ industrial }}</span>
                  </td>
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Lodos</b><br />
                    <span class="badge badge-success">{{ lodos }}</span>
                  </td>
                  <!-- Camiones de vacio -->
                  <td class="text-center" style="font-size: 13px; width: 06%">
                    <b>Camión de vacio</b><br />
                    <span class="badge badge-success">{{ vacio }}</span>
                  </td>
                  <td
                    class="text-center"
                    style="font-size: 13px; width: 06%"
                    v-if="form.vehiculosInact"
                  >
                    <b>Sin turno activo</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-12"
            v-if="
              Object.keys(vehiculosAct).length > 0 ||
                Object.keys(vehiculosPostulados).length > 0 ||
                Object.keys(vehiculosInAc).length > 0
            "
          >
            <TifMapasListVehiculos ref="TifMapasListVehiculos" />
          </div>
        </section>
        <TifMapasOptions ref="TifMapasOptions" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TifMapasOptions from "./TifMapasOptions";
import TifMapasListVehiculos from "./TifMapasListVehiculos";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import Loading from "../../../../components/Loading";
import "jquery-ui-dist/jquery-ui";
import $ from "jquery";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "TifMapaIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    TifMapasOptions,
    Loading,
    GmapCluster,
    TifMapasListVehiculos,
  },
  data() {
    return {
      html: null,
      cargando: false,
      infoContent: "",
      vehiculos: {},
      vehiculosAct: {},
      vehiculosPostulados: {},
      vehiculosInAc: {},

      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      form: {
        vehiculosAct: false,
        postulados: false,
        vehiculosInact: false,
      },
      filtersOperaciones: {
        aguasPotRie: false,
        pozos: false,
        residual: false,
        combustible: false,
        calderas: false,
        batePad: false,
        industrial: false,
        vacio: false,
        lodos: false,
      },
      filter: {
        checkSolicitud: false,
        checkTurno: false,
        solicitud_id: "",
        vehiculo_id: "",
        turno_id: "",
      },
      listasForms: {
        empresas: [],
        estados: [],
      },
      center: { lat: 4.651371, lng: -74.071509 },
      tilt: 45,
      heading: 90,
      zoom: 8,
      markers: [],
      markersTraza: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      solicitudes: [],
      traza: false,
      path: [],
      countPost: 0,
      countInactV: 0,
      countInact: 0,
      countActV: 0,
      aguaPoRi: 0,
      pozos: 0,
      residual: 0,
      combustible: 0,
      caldera: 0,
      bateria: 0,
      industrial: 0,
      lodos: 0,
      vacio: 0,
    };
  },

  methods: {
    async getIndex(page = 1) {
      let filters = this.$refs.TifMapasOptions.filter;
      if (
        Object.keys(this.vehiculosAct).length > 0 ||
        Object.keys(this.vehiculosPostulados).length > 0 ||
        Object.keys(this.vehiculosInAc).length > 0
      ) {
        let filters2 = this.$refs.TifMapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }
      filters.vehiculosAct = await this.form.vehiculosAct;
      filters.vehiculosInact = await this.form.vehiculosInact;
      filters.postulados = await this.form.postulados;

      let me = this;
      if (
        this.form.vehiculosAct ||
        this.form.postulados ||
        this.form.vehiculosInact
      ) {
        await axios
          .get("/api/tif/mapas/tablaMapa?page=" + page, {
            params: filters,
          })
          .then(function(response) {
            if (response.data[0].data !== undefined) {
              me.vehiculosAct = response.data[0];
            }
            if (response.data[1].data !== undefined) {
              me.vehiculosPostulados = response.data[1];
            }
            if (response.data[2].data !== undefined) {
              me.vehiculosInAc = response.data[2];
            }
          });
      } else {
        me.vehiculos = {};
        me.vehiculosPostulados = {};
        me.vehiculosAct = {};
        me.vehiculosInAc = {};
      }
    },

    async showCarros(act) {
      this.markers = [];
      let filters = this.$refs.TifMapasOptions.filter;
      if (
        Object.keys(this.vehiculosAct).length > 0 ||
        Object.keys(this.vehiculosPostulados).length > 0 ||
        Object.keys(this.vehiculosInAc).length > 0
      ) {
        let filters2 = this.$refs.TifMapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }

      if (filters.geocerca != null) {
        this.filterResult(filters.geocerca);
      }

      filters.vehiculosAct = await this.form.vehiculosAct;
      filters.vehiculosInact = await this.form.vehiculosInact;
      filters.postulados = await this.form.postulados;
      filters.producto_id = [];
      if (this.filtersOperaciones.aguasPotRie == true) {
        filters.producto_id = [29, 31];
      }

      if (this.filtersOperaciones.pozos == true) {
        filters.producto_id = [38, 45];
      }

      if (this.filtersOperaciones.combustible == true) {
        filters.producto_id = [33];
      }

      if (this.filtersOperaciones.calderas == true) {
        filters.producto_id = [34];
      }

      if (this.filtersOperaciones.industrial == true) {
        filters.producto_id = [30];
      }
      if (this.filtersOperaciones.lodos == true) {
        filters.producto_id = [55];
      }
      if (this.filtersOperaciones.batePad == true) {
        filters.producto_id = [41, 42, 43, 49];
      }

      if (this.filtersOperaciones.residual == true) {
        filters.producto_id = [32];
      }

      if(this.filtersOperaciones.vacio == true) {
        filters.producto_id = [52];
      }

      if (
        this.form.vehiculosAct ||
        this.form.postulados ||
        this.form.vehiculosInact
      ) {
        this.countInact = 0;
        this.countInactV = 0;
        this.countPost = 0;
        this.countActV = 0;
        this.aguaPoRi = 0;
        this.pozos = 0;
        this.residual = 0;
        this.combustible = 0;
        this.caldera = 0;
        this.bateria = 0;
        this.industrial = 0;
        this.lodos = 0;
        this.vacio = 0;
        if (act == 1) {
          this.cargando = true;
        }
        await axios
          .get("/api/tif/mapas/showCarros", {
            params: filters,
          })
          .then((response) => {
            this.getIndex();
            let loc = response.data;
            this.countInact = loc.countInact;
            this.countInactV = loc.countInactV;
            this.countPost = loc.countPost;
            this.countActV = loc.countActV;
            this.aguaPoRi = loc.aguaPoRi;
            this.pozos = loc.pozos;
            this.residual = loc.residual;
            this.combustible = loc.combustible;
            this.caldera = loc.caldera;
            this.bateria = loc.bateria;
            this.industrial = loc.industrial;
            this.lodos = loc.lodos;
            this.vacio = loc.vacio;
            loc.vehiculos.forEach((loc1) => {
              const marker = {
                lat: parseFloat(loc1.lat),
                lng: parseFloat(loc1.lon),
              };
              this.markers.push({
                position: marker,
                icon: loc1.icon,
                info: loc1.ventana,
                center: {
                  marker,
                },
                vehi_id: loc1.id,
              });
            });
            if (act == 1) {
              this.cargando = false;
            }
          });
      } else {
        this.getIndex();
      }
    },

    pollData() {
      this.polling = setInterval(() => {
        this.showCarros(2);
      }, 180000);
    },

    async showDraggable() {
      if (await this.traza) {
        $("#traza").css("display", "block");
        $("#traza").draggable();
      } else {
        $("#traza").css("display", "none");
      }
    },

    showTraza() {
      let me = this;
      this.cargando = true;
      this.markersTraza = [];
      if (this.filter.turno_id) {
        axios
          .get("/api/tif/mapas/traza", {
            params: this.filter,
          })
          .then((response) => {
            this.cargando = false;
            if (response.data.arregloTurno) {
              this.filter.vehiculo_id = "";
              this.puntosTraza = response.data.arregloTurno;
              if (this.puntosTraza.length > 0) {
                const marker = {
                  lat: parseFloat(this.puntosTraza[0].latitud),
                  lng: parseFloat(this.puntosTraza[0].longitud),
                };
                this.markersTraza.push({
                  id: this.puntosTraza[0].id,
                  position: marker,
                  info: this.puntosTraza[0].ventana,
                  icon: "/img/icon_punto_g_ok.png",
                });

                this.center = marker;
                this.zoom = 10;
              } else {
                this.$swal({
                  icon: "error",
                  title: "No existe traza para este Turno",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else if (response.data.mensaje) {
              this.$swal({
                icon: "error",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch(function(error) {
            me.cargando = false;
          });
      } else if (this.filter.vehiculo_id) {
        if (this.filter.fecha_inicio > this.filter.fecha_fin) {
          this.filter.fecha_fin = null;
          this.filter.fecha_inicio = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        } else {
          var fecha_menor = new Date(this.filter.fecha_inicio);
          var fecha_mayor = new Date(this.filter.fecha_fin);
          var diff = fecha_mayor - fecha_menor;
          var fecha_limite = diff / (1000 * 60 * 60 * 24);

          if (fecha_limite > 5) {
            this.filter.fecha_fin = null;
            this.filter.fecha_inicio = null;
            this.$swal({
              icon: "error",
              title: `Las fechas no pueden ser mayor a 5 días...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          } else {
            axios
              .get("/api/tif/mapas/traza", {
                params: this.filter,
              })
              .then((response) => {
                this.cargando = false;
                if (response.data.arregloVehiculo2) {
                  this.puntosTraza = response.data.arregloVehiculo2;

                  this.solicitudes = [];
                  const marker = {
                    lat: parseFloat(this.puntosTraza[0].latitud),
                    lng: parseFloat(this.puntosTraza[0].longitud),
                  };
                  this.markersTraza.push({
                    id: this.puntosTraza[0].id,
                    position: marker,
                    info: this.puntosTraza[0].ventana,
                    icon: "/img/icon_punto_g_ok.png",
                  });
                  this.center = marker;
                  this.zoom = 10;
                } else if (response.data.mensaje) {
                  this.$swal({
                    icon: "error",
                    title: response.data.mensaje,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              })
              .catch(function(error) {
                this.cargando = false;
              });
          }
        }
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    nextPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) + parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i < dist; i++) {
        if (this.markersTraza.length < this.puntosTraza.length) {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_viaje.png",
          });
          this.center = marker;
          this.zoom = 10;
          this.html = this.markersTraza[this.markersTraza.length - 1].info;
        } else {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_punto_g.png",
          });
          this.$swal({
            title: "Fin del recorrido!",
            text: "No se encontraron más tramas para este viaje",
            icon: "info",
          });

          break;
        }
      }
    },

    backPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) - parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i > dist; i--) {
        this.markersTraza.splice(i, 1);
        this.html = this.markersTraza[this.markersTraza.length - 1].info;
      }
    },

    limpiarMapa() {
      this.puntosTraza = [];
      this.markersTraza = [];
      this.viajes = [];
      this.filter.checkSolicitud = false;
      this.filter.checkTurno = false;
      this.filter.solicitud_id = "";
      this.filter.vehiculo_id = "";
      this.filter.turno_id = "";
    },

    setVentana(gps) {
      return (
        '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
        gps.strPlaca +
        "</h3><hr><b>Fecha reporte: </b> " +
        gps.dteFechaReporte +
        "<br> <b>Posicion: </b>" +
        gps.dblLatitud +
        ", " +
        gps.dblLongitud +
        "<br> <b>Velocidad: </b> " +
        gps.intVelocidadInstantanea +
        " Km/h" +
        "<br> <b>Direccion:</b> " +
        gps.strDireccion +
        "</div>"
      );
    },

    filterResult(geo) {
      for (let i = 0; i < this.poligonos.length; i++) {
        if (this.poligonos[i].id == geo.id) {
          const marker = {
            lat: parseFloat(this.poligonos[i].path[0].lat),
            lng: parseFloat(this.poligonos[i].path[0].lng),
          };
          this.markersTraza.push({
            position: marker,
            info: this.poligonos[i].info,
          });
          this.center = marker;
          this.zoom = 15;
        }
      }
    },

    cleanMarkers() {
      this.markersTraza = [];
    },

    async showGeocercas() {
      if (await this.form.geocercas) {
        this.cargando = true;
        await axios
          .get("/api/tif/mapas/showGeocercas")
          .then((response) => {
            this.cargando = false;
            this.locations = response.data.gps_fin;
            this.locations.geocerca_poli.forEach((geocerca) => {
              if (geocerca.puntos.length > 0) {
                let puntos = [];
                geocerca.puntos.forEach((punto) => {
                  puntos.unshift({
                    lng: parseFloat(punto.longitud),
                    lat: parseFloat(punto.latitud),
                  });
                });
                this.poligonos.unshift({
                  id: geocerca.id,
                  name: geocerca.desc,
                  path: puntos,
                  info: geocerca.ventana,
                  color: geocerca.color,
                });
              }
            });
            for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
              if (this.locations.geocerca_cir[j]["lat"] !== "") {
                this.circunferencias.unshift({
                  center: {
                    lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                    lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                  },
                  radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                  info: this.locations.geocerca_cir[j]["ventana"],
                  color: this.locations.geocerca_cir[j]["color"],
                });
              }
            }
          })
          .catch(function(error) {
            this.cargando = false;
          });
      } else {
        this.locations = {};
        this.circunferencias = [];
        this.poligonos = [];
      }
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  created() {
    this.pollData();
  },

  mounted() {},
};
</script>
